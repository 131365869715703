import {useMediaQuery} from "@react-hook/media-query";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import LanguageSelect from "../components/Footer/LanguageSelect";
import {WEBSITE_NAME} from "../constant";
import {LogoSidebar} from "./Header/LeftSidebar";
import i18n from "../i18nf/i18n";


export const Footer = ({ footerLink, className }: { footerLink: string, className?: string }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery("(max-width: 480px)");

    return (
        <footer className={`${className} flex flex-col  bg-gray-50  w-full m-auto relative snow`}>
            <div className="flex flex-col gap-2 m-auto w-full bg-gray-50  max-w-[64rem] py-4 px-4 lg:px-0">
                <div className="flex justify-between items-center">
                    <LogoSidebar className="text-[0.9rem] text-color-big-text font-bold lg:text-2xl"
                        title={isMobile ? "" : WEBSITE_NAME} />
                    <div className="flex gap-4 items-center">
                        <Link to={footerLink}
                              className="flex items-center justify-start gap-2 px-3 py-1 rounded-md bg-gray-50 hover:bg-base-200/20 md:py-1.5 md:px-4 w-full lg:w-52   hover:border-blue-300  transition duration-300 border-2 border-blue-400  font-bold ">
                            <img src="https://cdn-icons-png.flaticon.com/512/684/684904.png " width="30"
                                height="30" alt="" title="" className="img-small" />
                            <p className="font-bold text-color-big-text  lg:text-md hidden md:block items-center">{t("FooterContactUs")}</p>
                        </Link>
                        <LanguageSelect displayLabel />
                    </div>
                </div>
            </div>

            <CopyrightNotice text={t("CopyrightNotice")} />
        </footer>
    );
};

const CopyrightNotice = ({ text }: { text: string }) => {
    return (
        <div className="gap-3 bottom-0 border-t border-slate-300 text-center w-full p-4 bg-gray-50  text-slate-800 text-xs flex justify-center items-center">
            <div>
                &copy; {new Date().getFullYear()} {text}
            </div>
            <Link className="hover:underline" to={`/${i18n.language}/privacy-policy`}>
                Privacy Policy
            </Link>
        </div>
    )
}
