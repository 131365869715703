import CircularProgress from "@mui/material/CircularProgress";

type LoadingProps = {
    size?: number;
}

function Loading({size}: LoadingProps) {
    return <CircularProgress color="inherit" size={size || 100} thickness={5} className="w-10 h-10 text-color-big-text absolute flex top-0 left-0 right-0 bottom-0
                justify-center items-center m-auto"/>
}

export default Loading;