export const POSSIBLE_TRANSLATIONS_WORD_LIMIT = 8;
export const WEBSITE_NAME = "Pingo Family";
export const SOUND_TRAINING =
    "https://fluentfusion-static.s3.eu-central-1.amazonaws.com/training_end_sound.mp3";

export const [BASE_URL, SOCKET_URL] = get();

function get() {
    if (process.env.NODE_ENV === 'production') {
        return ["https://api.pingo.family", "wss://api.pingo.family/wss/"];
    } else {
        return ["https://api.pingo.family", "wss://api.pingo.family/wss/"];
        // return ["http://localhost:9090", "ws://localhost:1234"];
    }
}

// in days
export const STATISTICS_PERIOD = 7;


export const OG_DATA = {
    en: {
        title: "Boost Your Vocabulary with Pingo Family",
        description: "Master words, idioms, and slang for real-life speech.",
        url: "https://pingo.family/en",
        locale: "en_US",
    },
    es: {
        title: "Mejora tu Vocabulario con Pingo Family",
        description: "Domina palabras, expresiones y jerga para hablar bien.",
        url: "https://pingo.family/es",
        locale: "es_ES",
    },
    de: {
        title: "Erweitere deinen Wortschatz mit Pingo Family",
        description: "Meistere Wörter, Redewendungen und Slang für echte Gespräche.",
        url: "https://pingo.family/de",
        locale: "de_DE",
    },
};
