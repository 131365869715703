import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';
import i18n from '../../i18nf/i18n';
import SidebarRoutes from "../../routes/sidebar";
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

type LogoSidebarProps = {
    title: string;
    className?: string;
    classNameTitle?: string;
};

type LeftSidebarProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};


export const LogoSidebar = ({title, className, classNameTitle}: LogoSidebarProps) => {
    return (
        <ul>
            <li className={className}>
                <Link to={`/${i18n.language}`} className="flex items-center gap-2">
                    <img className="mask mask-squircle w-8 h-8 lg:w-10 lg:h-10 " src="/logo.png"
                         alt="PingoFamily Logo"/>
                    <span className={`text-2xl text-color-big-text  font-bold ${classNameTitle}`}>{title}</span>
                </Link>
            </li>
        </ul>
    );
};

function LeftSidebar({open, setOpen}: LeftSidebarProps) {
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const ref = useRef<null | HTMLDivElement>(null);
    const routes = SidebarRoutes();
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, setOpen]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleSidebar = () => {
        setOpen(!open);
    };

    return (
        <>
            {isMobile && (
                <Drawer className={`flex flex-col justify-between`} open={open} onClose={toggleSidebar} anchor="left">
                    <ul className="flex flex-col justify-end">
                        <div className="flex justify-between border-b-2 pl-2 w-full">
                            <LogoSidebar className="text-xl p-2 text-color-big-text font-bold"
                                         title="PingoLingo"/>
                            <IconButton onClick={toggleSidebar}>
                                <CancelOutlinedIcon className="w-6 h-6" />
                            </IconButton>
                        </div>
                        {routes.map((route, k) => (
                            <li className="p-4 hover:bg-blue-300" key={k}>
                                <NavLink
                                    end
                                    onClick={toggleSidebar}
                                    to={route.path}
                                    className={`flex gap-1 ${location.pathname === route.path && 'font-semibold'}`}
                                >
                                    {route.icon} {route.name}
                                    {location.pathname === route.path && (
                                        <span
                                            className="absolute inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-button-color"
                                            aria-hidden="true"></span>
                                    )}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </Drawer>
            )}
        </>
    );
}

export default LeftSidebar;
