import {Outlet} from "react-router-dom";
import {HeaderMain} from "../../components/MainPage/HeaderMain";
import {Footer} from "../../containers/Footer";


function MainPage({isValid}: { isValid: boolean }) {

    return (
        <>
            <div className="w-full h-100vh flex flex-col flex-1 bg-[#F4F9FF]">
                <HeaderMain isValid={isValid}/>
                <Outlet/>
                <Footer footerLink="contact-us"/>
            </div>
            {/* <CookiesComponent/> */}
        </>
    )
}

export default MainPage;
