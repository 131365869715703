import i18n from "i18next";
import {lazy, useEffect, useState} from "react";
import ReactGA from 'react-ga4';
import {NotificationContainer, NotificationManager,} from "react-notifications";
import "react-notifications/lib/notifications.css";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import initializeApp from "./app/init";
import Loading from "./components/Loading/Loading";
import MainPageContent from "./components/MainPage/MainPageContent";
import Training from "./components/Training/Training";
import {BASE_URL} from "./constant";
import NewPassword from "./features/user/NewPassword";
import PrivacyPolicy from "./features/user/privacy/PrivacyPolicy";
import Auth from "./pages/public/Auth";
import ContactUsPage from "./pages/public/ContactUs";
import FormLearning from "./pages/protected/FormLearning";
import FormNative from "./pages/protected/FormNative";
import {removeNotificationMessage} from "./store/headerSlice";
import {selectToken} from "./store/user/userSelector";
import {selectCurrentVocabularies} from "./store/vocabulary/vocabularySelector";
import CookiesComponent from './pages/protected/CookiesComponent';
import OpenGraphTags from "./features/config/OpenGraphTag";
import {logOut} from "./store/user/userSlice";
import MainPage from "./pages/public/MainPage";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/public/Login"));
const ForgotPassword = lazy(() => import("./pages/public/ForgotPassword"));
const Register = lazy(() => import("./pages/public/Register"));

initializeApp();
ReactGA.initialize("GTM-TZ4QHVM2")


function App() {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const vocabularies = useSelector(selectCurrentVocabularies);
    const { newNotificationMessage, newNotificationStatus } = useSelector(
        (state) => state.header,
    );
    const navigate = useNavigate();
    const isValidated = JSON.parse(sessionStorage.isValidated || "{}");
    const [isValid, setIsValid] = useState(isValidated?.valid || false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setIsValid(isValidated?.valid || false)
    }, [isValidated]);

    // CHECK_TOKEN_VALIDITY
    useEffect(() => {
        const checkTokenValidity = async () => {
            if (token) {
                setLoading(true);
                try {
                    const res = await fetch(
                        `${BASE_URL}/user/isTokenValid?token=${token}`,
                    );
                    const data = await res.json();

                    setIsValid(data.valid);
                    sessionStorage.setItem(
                        "isValidated",
                        JSON.stringify({
                            valid: data.valid,
                            token,
                        }),
                    );

                    if (
                        !data.valid &&
                        (window.location.href.includes("app") ||
                            window.location.href.includes("training"))
                    ) {
                        dispatch(logOut())
                        navigate(`/${i18n.language}/login`);
                    }
                } catch (error) {
                    console.error("Error checking token validity:", error);
                }
            }
            setLoading(false);
        };

        if (!isValid || !isValidated) {
            checkTokenValidity().then();
        }
    }, [dispatch, isValid, navigate, token, isValidated]);

    // CHECK_LANGUAGE
    useEffect(() => {
        const currentURL = location.pathname;
        const lang18n = i18n.language;
        let langFromUrl = currentURL.split("/")[1];

        if (!langFromUrl || langFromUrl === "") {
            if (lang18n) {
                navigate(`/${lang18n}`, { replace: true });
            } else {
                i18n.changeLanguage("en").then();
                navigate(`/en`, { replace: true });
            }
            return;
        }

        if (langFromUrl !== lang18n) {
            i18n.changeLanguage(langFromUrl).then();
            navigate(`${location.pathname.replace(lang18n, langFromUrl)}`, { replace: true });
        }
    }, [location.pathname, navigate]);

    // INITIATE_MESSAGE
    useEffect(() => {
        if (newNotificationMessage !== "") {
            if (newNotificationStatus === 2) NotificationManager.info(newNotificationMessage, 'Info')
            else if (newNotificationStatus === 1) NotificationManager.success(newNotificationMessage, 'Success')
            else if (newNotificationStatus === 0) NotificationManager.error(newNotificationMessage, 'Error')
            dispatch(removeNotificationMessage())
        }
    }, [newNotificationMessage, dispatch, newNotificationStatus]);

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <OpenGraphTags />
            <NotificationContainer />
            <Routes>
                <Route index element={<Navigate to={"/" + (i18n.language ?? 'en')} replace />} />
                <Route path="/" element={<Navigate to={"/" + (i18n.language ?? 'en')} replace />} />
                <Route path=":lang" element={<MainPage isValid={isValid} />}>
                    <Route index element={<MainPageContent />} />
                    <Route path="contact-us" element={<ContactUsPage />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                </Route>

                <Route path=":lang/training">
                    <Route index element={<Training />} />
                    <Route path="*" element={<Navigate to={`/${i18n.language}/training`} />} />
                </Route>

                <Route path=":lang">
                    <Route path="login" element={<Login />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="register" element={<Register />} />
                    <Route path="new-password/:token" element={<NewPassword />} />
                    <Route path="auth" element={<Auth />} />

                    <Route path="*" element={<Layout />} />

                    <Route path="form">
                        <Route path="learning" element={<FormLearning token={token} vocabularies={vocabularies} />} />
                        <Route path="native" element={<FormNative />} />
                    </Route>
                </Route>
                <Route path="*" element={<Navigate to={`/${i18n.language ?? 'en'}`} replace />} />
            </Routes>
            <CookiesComponent />
        </>
    );
}

export default App;
