import {store} from '../app/store';
import {WordIndex} from '../components/Training/trainingTypes/standard/PhraseConstruction';
import {addToFinalTrainingSequence, incrementMistakeNumber,} from '../store/training/trainingSlice';
import {TrainingExampleWithIndex} from '../store/training/trainingTypes';
import {Dispatch, RefObject, SetStateAction} from "react";
import {API} from "../app/init";

const spanishArticles = ['el', 'la', 'los', 'las', 'un', 'una', 'unos', 'unas', 'el/la', 'la/los'];
const germanArticles = ['der', 'die', 'das', 'des', 'den', 'dem', 'ein', 'eine', 'einen', 'einem', 'einer'];
const englishArticles = ['a', 'an', 'the'];


export const articles = new Map([
    ["GERMAN", germanArticles],
    ["SPANISH", spanishArticles],
    ["ENGLISH", englishArticles]
])

export type Ref<T> = RefObject<T>

export type HtmlInputElement = HTMLInputElement | null;
export type HtmlImageElement = HTMLImageElement | null;

export const completeInputTrainingRef = (ref: Ref<HtmlInputElement | HTMLTextAreaElement>) => {
    if (ref.current) {
        ref.current.style.color = 'black';
        ref.current.style.border = '';
        ref.current.disabled = true;
    }
}

export const getHintWord = (text: string, lang: string): string => {
    const {word, article} = removeArticles(text, lang)

    return (article ? article + " " : "") + word.slice(0, 2);
}

export const addToCurrentCursorPosition = (setInput: Dispatch<SetStateAction<string>>,
                                           textToAdd: string,
                                           inputRef?: Ref<HtmlInputElement | HTMLTextAreaElement>,
                                           cursorPosition?: number): void => {
    const cursor = cursorPosition ?? inputRef?.current?.selectionStart ?? -1;
    if (cursor === -1) {
        setInput(prev => prev + textToAdd);
    } else {
        setInput(prev => prev.slice(0, cursor) + textToAdd + prev.slice(cursor));
    }

    inputRef?.current && inputRef.current.focus();
    inputRef?.current && inputRef.current.setSelectionRange(cursor + textToAdd.length, cursor + textToAdd.length);
}

export const removeArticles = (text: string, lang: string): { article: string, word: string } => {
    let article = "";
    let arr: string[];

    if (lang === "all") {
        arr = Array.from(articles.values()).flat()
    } else {
        arr = articles.has(lang) ? articles.get(lang) ?? [] : [];
    }

    for (let value of arr) {
        for (let e of text.split(" ")) {
            if (value.includes(e.trim())) {
                article += e.trim();
                text = text.replace(e.trim(), "");
            }
        }
    }

    return {article, word: text.trim()}
}

export const isCorrect = (input: string, word: string, lang: string): boolean => {
    // debugger

    const {word: wordWithoutArticle} = removeArticles(word, lang)
    const {word: inputWithoutArticle} = removeArticles(input, lang)

    return removeSpecialCharacters(inputWithoutArticle.toLowerCase()).trim() === removeSpecialCharacters(wordWithoutArticle.toLowerCase()).trim();
}

export const hintTriggerEvent = (setInput: Dispatch<SetStateAction<string>>, word: string, inputRef?: Ref<HtmlInputElement>, imageRef?: Ref<HtmlImageElement>) => {
    const hintWord = getHintWord(word, "all");
    setInput(hintWord);
    imageRef && revealImage(imageRef)
    inputRef?.current && inputRef.current.focus();
    inputRef?.current && inputRef.current.setSelectionRange(hintWord.length, hintWord.length);
}

export const revealAnswer = (setInput: Dispatch<SetStateAction<string>>, word: string, elem: TrainingExampleWithIndex, imageRef?: Ref<HtmlImageElement>): void => {
    setInput(word);

    imageRef && revealImage(imageRef)
    store.dispatch(addToFinalTrainingSequence(elem))
    store.dispatch(incrementMistakeNumber({index: elem.index, trainingType: elem.trainingExample.trainingType}))
}

export const revealImage = (imageRef: Ref<HtmlImageElement>) => {
    if (imageRef?.current) {
        imageRef.current.style.visibility = 'visible';
    }
}

export const sendCompleteMessage = async (body: {}, token: string) => {
    return await API.post('/learning/complete', body)
}

export const removeSpecialCharacters = (text: string) => {
    // eslint-disable-next-line no-control-regex
    let regex = /[^\p{L}\p{N}\s#]/gu

    return text.replace(regex, '');
}

export const insert = (array: any, object: WordIndex) => {
    const temp = [...array]
    temp[object.index] = object;
    // return [...array.slice(0, object.index), object, ...array.slice(object.index)]
    return temp;
}

export const constructMap = (object: {}): Map<string, string[]> => {
    if (!object) return new Map<string, string[]>();

    const map = new Map<string, string[]>();

    Object.entries(object).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            map.set(key, value as string[]);
        } else {
            map.set(key, []);
        }
    });

    return map;
}
export const playSound = (source: string | undefined) => {
    if (!source) return;
    const audio = new Audio(source);
    try {
        audio.play()
    } catch (error: any) {
        console.error("Error appeared in playSound()", error)
        throw new Error("Error appeared in playSound()")
    }

    return audio;
}

export const fetchTraining = async (training: { vocabularyId: number, vocabularyGroupId: number }) => {
    return await API.post(`/learning/generate`, training);
}

export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export const completeTraining = async (finalTrainingSequence: TrainingExampleWithIndex[], trainingSessionId: number, token: string) => {
    const mistakes: {
        trainingId: number,
        mistakes: CompleteTrainingSecondLevelResponse[]
    }[] = []


    const groupedByTrainingId = groupBy(finalTrainingSequence, (training) => training.trainingId)

    for (let [key, value] of groupedByTrainingId) {
        const visited: number[] = []
        const temp: CompleteTrainingSecondLevelResponse[] = []

        for (let elem of value) {
            if (visited.includes(elem.trainingExample.id)) continue;

            visited.push(elem.trainingExample.id)
            temp.push({
                trainingExampleId: elem.trainingExample.id,
                hint: elem.hint,
                skipped: elem.skipped,
                mistakeCount: elem.mistakeCount
            });
        }

        mistakes.push({
            trainingId: key,
            mistakes: [...temp]
        })
    }


    const json = {
        trainingSessionId,
        mistakes: [...mistakes]
    }

    return await sendCompleteMessage(json, token)
}

export type CompleteTrainingSecondLevelResponse = {
    trainingExampleId: number,
    hint: boolean,
    skipped: boolean,
    mistakeCount: number
}

export const compareCollapsed = (first: string, second: string) => {
    return removeSpecialCharacters(first.toLowerCase()).replaceAll(" ", "")
        === removeSpecialCharacters(second.toLowerCase()).replaceAll(" ", "");
}
