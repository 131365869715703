import {createSlice} from "@reduxjs/toolkit";
import {setAuthToken} from "../../app/localStorageService";

export type TrainingStatistics = {
    trainingId: number,
    date: number,
    accuracy: string,
    numberOfWords: number,
}
export type UserState = {
    readonly theme: string,
    readonly token: string,
    readonly roomCode: string,
    readonly lastPickedVocabularyId: number;
    readonly email: string;
    readonly name: string;
    readonly profileImageUrl: string;
    readonly isValid: boolean;
    readonly currentStreak: number,
    readonly maxStreak: number,
}

export const USER_INITIAL_STATE: UserState = {
    isValid: false,
    theme: 'light',
    token: '',
    roomCode: '',
    lastPickedVocabularyId: 0,
    profileImageUrl: "",
    email: "",
    name: "",
    currentStreak: 0,
    maxStreak: 0,
};


export const userSlice = createSlice({

    name: 'user',
    initialState: USER_INITIAL_STATE,
    reducers: {
        setTheme(state, action) {
            state.theme = action.payload;
        },
        incrementCurrentStreak(state) {
            state.currentStreak += 1
        },
        incrementMaxStreak(state) {
            state.maxStreak += 1
        },
        setToken(state, action) {
            setAuthToken(action.payload);
            state.token = action.payload;
        },
        logOut(state) {
            state.email = USER_INITIAL_STATE.email;
            state.lastPickedVocabularyId = USER_INITIAL_STATE.lastPickedVocabularyId;
            state.name = USER_INITIAL_STATE.name;
            state.profileImageUrl = USER_INITIAL_STATE.profileImageUrl;
            state.token = USER_INITIAL_STATE.token;
            state.roomCode = USER_INITIAL_STATE.roomCode;
            localStorage.removeItem("authToken");
        },
        setUserDetails(state, action) {
            state.roomCode = action.payload.roomCode;
            state.lastPickedVocabularyId = action.payload.lastPickedVocabularyId;
            state.email = action.payload.email;
            state.name = action.payload.name;
            state.profileImageUrl = action.payload.profileImageUrl;
            state.currentStreak = action.payload.currentStreak;
            state.maxStreak = action.payload.maxStreak;
        },
        setName(state, action) {
            state.name = action.payload;
        },
        setIsValid(state, action) {
            state.isValid = action.payload;
        }
    }
});

export const userReducer = userSlice.reducer;
export const {setTheme, setToken, logOut, setUserDetails, setName, setIsValid} = userSlice.actions;
