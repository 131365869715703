import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource} from "axios";
import {BASE_URL} from "../constant";
import {getAuthToken} from "./localStorageService";
import {store} from "./store";

let tokenRedux = store.getState().user?.token;
let token = tokenRedux ?? getAuthToken();
store.subscribe(() => {
  token = store.getState().user?.token;
});
let CANCEL_TOKEN_SOURCE = axios.CancelToken.source();

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  cancelToken: CANCEL_TOKEN_SOURCE.token,
});

function getDefaultHeaders(headers: {} = {}) {
  return token
    ? {
      Authorization: `Bearer ${token}`,
      ...headers,
    }
    : { ...headers };
}

const currentRequests = new Map<string, CancelTokenSource>();

export const API = {
  get<DataResponseType = any>(
    endpoint: string,
    config: AxiosRequestConfig = {},
    cancelToken: CancelTokenSource | null = null
  ): Promise<AxiosResponse<DataResponseType>> {
    if (currentRequests.has(endpoint)) {
      currentRequests.get(endpoint)?.cancel();
    }

    const cToken = cancelToken ? cancelToken : axios.CancelToken.source();

    const req = axiosInstance
      .get<DataResponseType>(endpoint, {
        ...config,
        headers: getDefaultHeaders(config.headers),
        cancelToken: cToken.token,
      })
      .then((e) => {
        currentRequests.delete(endpoint);
        return e;
      });

    currentRequests.set(endpoint, cToken);
    return req;
  },
  post<DataResponseType = any>(
    endpoint: string,
    data: {} | [],
    config: AxiosRequestConfig = {},
    cancelToken: CancelTokenSource | null = null
  ): Promise<AxiosResponse<DataResponseType>> {
    if (currentRequests.has(endpoint)) {
      currentRequests.get(endpoint)?.cancel();
    }

    const cToken = cancelToken ? cancelToken : CANCEL_TOKEN_SOURCE;

    currentRequests.set(endpoint, cToken);
    const req = axiosInstance
      .post<DataResponseType>(endpoint, data, {
        ...config,
        headers: getDefaultHeaders(config.headers),
        cancelToken: cToken.token,
      })
      .then((e) => {
        return e;
      });
    currentRequests.delete(endpoint);

    return req;
  },
  put<DataResponseType = any>(
    endpoint: string,
    data: {} | [],
    config: AxiosRequestConfig = {},
    cancelToken: CancelTokenSource | null = null
  ): Promise<AxiosResponse<DataResponseType>> {
    if (currentRequests.has(endpoint)) {
      currentRequests.get(endpoint)?.cancel();
    }

    const cToken = cancelToken ? cancelToken : CANCEL_TOKEN_SOURCE;

    const req = axiosInstance
      .put<DataResponseType>(endpoint, data, {
        ...config,
        headers: getDefaultHeaders(config.headers),
        cancelToken: cToken.token,
      })
      .then((e) => {
        currentRequests.delete(endpoint);
        return e;
      });

    currentRequests.set(endpoint, cToken);
    return req;
  },

  delete<DataResponseType = any>(
    endpoint: string,
    config: AxiosRequestConfig = {},
    cancelToken: CancelTokenSource | null = null
  ): Promise<AxiosResponse<DataResponseType>> {
    if (currentRequests.has(endpoint)) {
      currentRequests.get(endpoint)?.cancel();
    }

    const cToken = cancelToken ? cancelToken : axios.CancelToken.source();

    const req = axiosInstance
      .delete<DataResponseType>(endpoint, {
        ...config,
        headers: getDefaultHeaders(config.headers),
        cancelToken: cToken.token,
      })
      .then((e) => {
        currentRequests.delete(endpoint);
        return e;
      });

    currentRequests.set(endpoint, cToken);
    return req;
  },

  finishPendingRequestsLike(regex: string) {
    currentRequests.forEach((value, key) => {
      if (key.match(regex)) {
        value.cancel();
      }
    });
  },
};

const initializeApp = () => {
  axios.defaults.baseURL = BASE_URL;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // dev code
  } else {
    // Removing console.log from prod
    console.log = () => { };
  }
};

export default initializeApp;
