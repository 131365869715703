import {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import {useDispatch} from 'react-redux';
import {SOUND_TRAINING} from "../../../../constant";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
    incrementFinalSequenceIndex,
    incrementHintsNumber,
    incrementMistakeNumber
} from "../../../../store/training/trainingSlice";
import {
    addToCurrentCursorPosition,
    hintTriggerEvent,
    HtmlImageElement,
    isCorrect,
    playSound,
    revealAnswer
} from "../../../../utils/trainingUtils";
import {TrainingTypesProps} from "../../Training";
import TrainingBlock from "../TrainingBlock";
import {getBlockWidth} from "../../../../utils/globalUtils";
import TrainingInput from "./TrainingInput";
import TrainingImage from '../TrainingImage';
import {motion} from "framer-motion";
import {Language} from "../../../../store/languageSlice";

const AudioTraining = ({elem, targetWord}: TrainingTypesProps) => {
    console.log('targetword: ', targetWord)
    const dispatch = useDispatch();
    const {wordFrom} = targetWord.wordTranslation;
    const {imageUrl, soundUrl} = targetWord.wordTranslation.wordFrom;
    const [correct, setCorrect] = useState<boolean>(false);
    const [input, setInput] = useState<string>("");
    const [backupInput, setBackupInput] = useState<string | undefined>("");
    const [hint, setHint] = useState<boolean>(false);
    const [skipped, setSkipped] = useState<boolean>(false);
    const identifiedWord = elem.trainingExample.identifiedWord;

    const inputRef = useRef<HTMLInputElement>(null);
    const imageRef = useRef<HtmlImageElement>(null)

    const onChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
        setInput(event.target.value);
    }

    const resetInput = useCallback((incrementFinalSequence: boolean = true) => {
        if (imageRef.current) {
            imageRef.current.style.visibility = 'visible';
        }

        setBackupInput("")
        setInput("");
        setCorrect(false);
        setHint(false);
        setSkipped(false);
        if (incrementFinalSequence) {
            dispatch(incrementFinalSequenceIndex())
        }

    }, [dispatch])


    const onClickEndTraining = () => {
        if (correct) {
            resetInput();
        } else {
            showCorrectAnswer()
        }
    }
    const completeTraining = useCallback((localSkipped: boolean) => {
        setCorrect(true)

        playSound(SOUND_TRAINING)
        // .then(message => console.log(message))
        // .catch(error => console.error("Error:", error));

        playSound(targetWord.wordTranslation.wordFrom.soundUrl)
        // .then(message => console.log(message))
        // .catch(error => console.error("Error:", error));

        if (imageRef.current) {
            imageRef.current.style.visibility = 'visible'
        }

        if (!localSkipped) {
            setBackupInput(elem.trainingExample.identifiedWord)
            setInput("")
            setTimeout(() => {
                resetInput();
            }, 2000)
        }
    }, [elem.trainingExample.identifiedWord, resetInput, targetWord.wordTranslation.wordFrom.soundUrl])

    const hintClickTrigger = () => {
        hintTriggerEvent(setInput, identifiedWord, inputRef)
        dispatch(incrementHintsNumber({index: elem.index, trainingType: elem.trainingExample.trainingType}))
        setHint(true);
    }

    const showCorrectAnswer = () => {
        setSkipped(true);
        completeTraining(true)
        revealAnswer(setInput, identifiedWord, elem, imageRef)
        setInput(identifiedWord);
        dispatch(incrementMistakeNumber({index: elem.index, trainingType: elem.trainingExample.trainingType}))
    }

    useEffect(() => {
        if (soundUrl) {
            setTimeout(() => {
                playSound(soundUrl)
            }, 500)
        }
    }, [soundUrl])


    useEffect(() => {
        if (!correct && isCorrect(input || "", elem.trainingExample.identifiedWord, wordFrom.language as Language)) {
            completeTraining(false)
        }
    }, [correct, completeTraining, elem.trainingExample.identifiedWord, input, wordFrom.language]);

    console.log("currently trained word: ", elem, identifiedWord)

    useEffect(() => {
        resetInput(false)
    }, [resetInput]);

    return (
        <TrainingBlock isSkipped={skipped} isCorrect={correct} showHint={hintClickTrigger}
                       addChar={c => addToCurrentCursorPosition(setInput, c, inputRef)}
                       showCorrectAnswer={showCorrectAnswer} handleSkipClick={onClickEndTraining}>
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 0.5}}
                        className={`flex flex-col items-center`}>
                <TrainingImage ref={imageRef} src={imageUrl}/>
                <button onClick={() => playSound(soundUrl)}>
                    <VolumeUpIcon
                        className="w-6 h-6 text-color-big-text hover"/>
                </button>

                {/*{targetWord.wordTranslation.wordFrom.soundUrl && <audio autoPlay src={targetWord.wordTranslation.wordFrom.soundUrl}/>}*/}

                <TrainingInput
                    ref={inputRef}
                    width={getBlockWidth(identifiedWord)}
                    value={!input || input.length === 0 ? backupInput : input}
                    onChangeEvent={onChangeEvent}
                    correct={correct ?? false}
                    trainingExampleId={elem.trainingExample.id}
                    correctValue={identifiedWord}
                />

                <span
                    className={`${hint || correct ? "visible animation" : "invisible"} text-2xl 
                    text-color-big-text text-center`}>
                {targetWord.wordTranslation.wordTo.word}
            </span>
            </motion.div>
        </TrainingBlock>
    )
}
export default AudioTraining
