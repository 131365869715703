import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {OG_DATA} from "../../constant";


const OpenGraphTags = () => {
    const { i18n } = useTranslation();
    const currentLang = i18n.language || "en";
    // @ts-ignore
    const { title, description, url, locale } = OG_DATA[currentLang] || OG_DATA.en;
  
    return (
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={locale} />
        <meta property="og:site_name" content="Pingo Family" />
      </Helmet>
    );
};

export default OpenGraphTags;
