type Callback = (token: string) => void;
const subscribers = new Set<Callback>();

export function getAuthToken() {
    return localStorage.getItem("authToken");
}

// Notify all subscribers when authToken changes
export function setAuthToken(token: string) {
    localStorage.setItem("authToken", token);
    notifySubscribers();
}

export function subscribeAuthToken(callback: Callback) {
    subscribers.add(callback);
    return () => subscribers.delete(callback); // Unsubscribe function
}

function notifySubscribers() {
    const newToken = getAuthToken();
    if (newToken === null) return;

    subscribers.forEach((callback) => callback(newToken));
}

// Listen for changes in other tabs
window.addEventListener("storage", (event) => {
    if (event.key === "authToken") {
        notifySubscribers();
    }
});
