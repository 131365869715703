import Squares2X2Icon from '@heroicons/react/24/outline/Squares2X2Icon';
import {useTranslation} from "react-i18next";
import i18n from '../i18nf/i18n';

const iconClasses = `h-6 w-6`;

const SidebarRoutes = () => {
    const {t} = useTranslation();

    return [
        {
            path: `/${i18n.language ?? "en"}/vocabularies`,
            icon: <Squares2X2Icon className={iconClasses}/>,
            name: t('HeaderVocabularies'),
        }
    ];
};

export default SidebarRoutes;