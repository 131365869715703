import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {SOUND_TRAINING} from "../../../../constant";
import {Language} from "../../../../store/languageSlice";
import {incrementFinalSequenceIndex, incrementHintsNumber} from "../../../../store/training/trainingSlice";
import {getBlockWidth} from "../../../../utils/globalUtils";
import {
    addToCurrentCursorPosition,
    hintTriggerEvent,
    isCorrect,
    playSound,
    revealAnswer
} from "../../../../utils/trainingUtils";
import {TrainingTypesProps} from "../../Training";
import TrainingBlock from "../TrainingBlock";
import TrainingImage from "../TrainingImage";
import TrainingInput from "./TrainingInput";

const TranslationTraining = ({targetWord, elem}: TrainingTypesProps) => {
    const dispatch = useDispatch();
    const [input, setInput] = useState<string>("");
    const [backupInput, setBackupInput] = useState<string | undefined>("");
    const [correct, setCorrect] = useState<boolean>(false);
    const [skipped, setSkipped] = useState<boolean>(false);
    const wordFrom = targetWord.wordTranslation.wordFrom;

    const inputRef = React.useRef<HTMLInputElement>(null);

    const onChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value)
    }

    const resetState = useCallback((incrementFinalSequence: boolean = true) => {
        setInput("")
        setBackupInput("")
        setCorrect(false)
        setSkipped(false)
        if (incrementFinalSequence) {
            dispatch(incrementFinalSequenceIndex())
        }

    }, [dispatch])

    const completeTraining = useCallback((localSkipped: boolean) => {
        setCorrect(true)

        playSound(SOUND_TRAINING)

        setTimeout(() => {
            playSound(targetWord.wordTranslation.wordFrom.soundUrl)
        }, 200)

        if (!localSkipped) {
            console.log('auto-sip')
            setBackupInput(elem.trainingExample.identifiedWord)
            setInput("")
            setTimeout(() => {
                resetState()
            }, 1000)
        }
    }, [elem.trainingExample.identifiedWord, resetState, targetWord.wordTranslation.wordFrom.soundUrl])

    const showCorrectAnswer = () => {
        setCorrect(true);
        revealAnswer(setInput, elem.trainingExample.identifiedWord, elem)
        completeTraining(true)
        setSkipped(true)
    }

    const showHint = () => {
        hintTriggerEvent(setInput, elem.trainingExample.identifiedWord, inputRef)
        dispatch(incrementHintsNumber({index: elem.index, trainingType: elem.trainingExample.trainingType}))
    }

    const handleSkipClick = () => {
        if (correct) {
            resetState()
        } else {
            showCorrectAnswer()
        }
    }

    useEffect(() => {
        if (!correct && isCorrect(input || "", elem.trainingExample.identifiedWord, wordFrom.language as Language)) {
            completeTraining(false)
        }
    }, [completeTraining, correct, elem.trainingExample.identifiedWord, input, wordFrom.language]);


    useEffect(() => {
        resetState(false)
    }, [resetState]);

    return (
        <div className="overflow-hidden">
            <TrainingBlock showCorrectAnswer={showCorrectAnswer} isSkipped={skipped} isCorrect={correct}
                           addChar={(c) => addToCurrentCursorPosition(setInput, c, inputRef)}
                           handleSkipClick={handleSkipClick} showHint={showHint}>

                <div className={`flex flex-col items-center`}>
                    <TrainingImage src={targetWord.wordTranslation.wordFrom.imageUrl} className='mb-0'/>
                    <span className={`text-2xl text-center mt-1`}>
                        {targetWord.wordTranslation.wordTo.word}
                    </span>
                    {/*{correct && <audio className="" autoPlay src={targetWord.wordTranslation.wordFrom.soundUrl}/>}*/}
                    <TrainingInput
                        ref={inputRef}
                        // key={`${elem.trainingExample.id}:${elem.trainingExample.identifiedWord}`}
                        width={getBlockWidth(elem.trainingExample.identifiedWord)}
                        onChangeEvent={onChangeEvent}
                        value={!input || input.length === 0 ? backupInput : input}
                        correct={correct ?? false}
                        trainingExampleId={elem.trainingExample.id}
                        correctValue={elem.trainingExample.identifiedWord}
                    />
                </div>

            </TrainingBlock>
        </div>
    )
}
export default TranslationTraining
