import {forwardRef, Ref} from "react"

const TrainingImage = forwardRef(({src, className}: {
    src: string,
    className?: string
}, ref: Ref<HTMLImageElement>) => {
    return (
        <img
             ref={ref}
             src={src ? src : `${process.env.PUBLIC_URL}/empty.png`} alt=''
             className={`training-image animation ${className}`}/>
    )
});

export default TrainingImage
