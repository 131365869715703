import {TrainingTypesProps} from "../../Training";
import TrainingBlock from "../TrainingBlock";
import {useDispatch} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {
    addToCurrentCursorPosition,
    compareCollapsed,
    completeInputTrainingRef,
    playSound,
    revealAnswer
} from "../../../../utils/trainingUtils";
import {incrementFinalSequenceIndex} from "../../../../store/training/trainingSlice";
import TextAreaInput from "../../../Input/TextAreaInput";
import {SOUND_TRAINING} from "../../../../constant";
import {motion} from "framer-motion";

const SentenceType = ({elem, targetWord}: TrainingTypesProps) => {
    const dispatch = useDispatch();
    const {word, imageUrl} = targetWord.wordTranslation.wordFrom;
    const [input, setInput] = useState<string>("");
    const [sentence, setSentence] = useState<string>("");
    const [correct, setCorrect] = useState<boolean>(false);
    const [skipped, setSkipped] = useState<boolean>(false);

    const textareaRef = useRef<HTMLTextAreaElement>(null)

    const onChangeEvent = (val: string) => {
        setInput(val);
    }

    const showCorrectAnswer = () => {
        setSkipped(true)
        setCorrect(true)
        setInput(elem.trainingExample.sentence);
        revealAnswer(setInput, elem.trainingExample.sentence, elem)
    }

    const handleSkipClick = () => {
        if (correct) {
            setInput("")
            setCorrect(false)
            setSkipped(false)
            dispatch(incrementFinalSequenceIndex())
        } else {
            showCorrectAnswer()
        }
    }

    useEffect(() => {
        setSentence(elem.trainingExample.sentenceTranslation);
        // textareaRef.current?.focus()
    }, [elem.trainingExample.formattedString, elem.trainingExample.sentenceTranslation, imageUrl])

    useEffect(() => {
        if (!correct && compareCollapsed(input || "", elem.trainingExample.sentence)) {

            setCorrect(true)
            completeInputTrainingRef(textareaRef)
            playSound(SOUND_TRAINING)
                // .then(message => console.log(message))
                // .catch(error => console.error("Error:", error));
            setTimeout(() => {
                playSound(elem.trainingExample.soundUrl)
            }, 500)

            if (!skipped) {
                setTimeout(() => {
                    setInput("")
                    setSkipped(false)
                    setCorrect(false)
                    dispatch(incrementFinalSequenceIndex())
                }, 4000)
            }
        }
    }, [input, word, correct, dispatch, targetWord.wordTranslation.wordFrom.soundUrl, elem.trainingExample.sentence, elem.trainingExample.soundUrl, skipped])


    return (
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 0.5}}
                    className={`flex flex-col items-center`}>
            <TrainingBlock isSkipped={skipped} isCorrect={correct} showCorrectAnswer={showCorrectAnswer}
                           addChar={c => addToCurrentCursorPosition(setInput, c, textareaRef)}
                           handleSkipClick={handleSkipClick}>
                <span className='text-3xl font-semibold my-2 max-md:text-2xl max-md:mt-3 max-md:mb-3'>{sentence}</span>
                <TextAreaInput ref={textareaRef} autoFocus disabled={correct} value={input ?? ""}
                               updateFormValue={onChangeEvent} defaultValue={input} labelStyle="" labelValue=''/>

            </TrainingBlock>
        </motion.div>
    )
        ;
}

export default SentenceType;
